import React, { useState } from "react";
import { PopupModal } from "react-calendly";
import styled from "styled-components";

const RequestButton = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <CTA onClick={() => setOpen(true)}>Request a free demo</CTA>
      <PopupModal
        url="https://calendly.com/seamlessvisa-consultations/request-demo"
        // pageSettings={this.props.pageSettings}
        // utm={this.props.utm}
        // prefill={this.props.prefill}
        onModalClose={() => setOpen(false)}
        open={open}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root") as HTMLElement}
      />
    </>
  );
};

export default RequestButton;

const CTA = styled.button`
  width: 222px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  background: #0076e9;
  border-radius: 8px;
  color: #f9fcff;

  &:hover {
    background: #022e58;
    color: #f9fcff;
    transition: 0.3s ease-in-out;
  }

  margin: auto;

  @media only screen and (min-width: 768px) {
    margin: 40px 0;
  }
`;
