import React from "react";
import styled from "styled-components";
// noinspection SpellCheckingInspection
import Constaint from "../../layout/components/Constaint";
import Colors from "../../../../styles/Colors";
import { StepsData } from "./data";
import { motion } from "framer-motion";
// import { listItemContainerVariant, listItemVariant } from "./variants";
import { mediaObj } from "../../../../styles/Media";
import { useNavigate } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";
import { listItemContainerVariant, listItemVariant } from "./variants";

const Steps = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <p className="uppercase text-center mt-6 mb-10">why seamlessvisa</p>
          <H2>
            A Smarter Way to Navigate <Colored>Immigration</Colored>
          </H2>
          <Label>
            No more visa application stress! Our attorney-reviewed software
            guides you effortlessly, making sure your paperwork is perfect.
          </Label>

          <CardGrid
            variants={listItemContainerVariant}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true }}
          >
            {StepsData.map((step) => (
              <GridCard
                align={step.align}
                bg={step.bg}
                gridArea={step.gridArea}
                key={step.bg}
                iconBg={step.iconBg}
                pwidth=""
                variants={listItemVariant}
              >
                <div>{step.img}</div>
                <h2>{step.name}</h2>
                <p>{step.subtext}</p>
              </GridCard>
            ))}
          </CardGrid>
          <CardBtn onClick={() => navigate(WebPaths.Services)}>
            Get Started
          </CardBtn>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default Steps;

const Wrapper = styled.div`
  background-color: white;
`;

const Container = styled.div`
  padding-top: 61px;
  padding-bottom: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: #31393c;
  margin-top: 46px;
  margin-bottom: 40px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    width: 70%;
  }
`;

const H2 = styled.h2`
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.6875rem;
  letter-spacing: 0em;
  color: #31393c;

  text-align: center;

  @media only screen and (min-width: 768px) {
    width: 40%;
  }
`;

const Colored = styled.span`
  color: #cfa202;
`;

// const CardFlex = styled(motion.div)`
//   display: flex;
//   align-items: center;
//   flex-direction: column;
//   gap: 20px;

//   @media only screen and (min-width: 768px) {
//     flex-direction: row;
//   }
// `;

// const Card = styled(motion.div)`
//   width: 427px;
//   height: 468px;
//   border: 1px solid #d0d0d0;
//   border-radius: 12px;
//   padding: 0 34px;
//   padding-top: 60px;
//   padding-bottom: 76px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 43px;

//   &:hover {
//     border: 1px solid #0076e9;
//     transition: 0.3s ease-in-out;
//   }

//   ${mediaObj.mobile} {
//     width: 100%;
//     height: max-content;
//   }
// `;

// const ImageBox = styled.div`
//   height: 115px;
//   width: 124px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border: 1px solid #0076e9;
//   border-radius: 12px;
//   padding: 26px 30px;
// `;

// const CardImage = styled.img`
//   height: 64px;
//   width: 64px;
// `;

// const CardContent = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 24px;
// `;

// const CardName = styled.h5`
//   font-weight: 600;
//   font-size: 2rem;
//   line-height: 2.375rem;
//   color: #31393c;
//   text-align: center;
// `;

// const CardPara = styled.p`
//   font-size: 1.25rem;
//   font-weight: 400;
//   line-height: 1.75rem;
//   letter-spacing: 0em;
//   text-align: center;
// `;

const CardBtn = styled.button`
  width: 426px;
  height: 56px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-transform: uppercase;
  color: #f9fcff;
  background: ${Colors.Blue00};
  margin-top: 86px;

  &:hover {
    background: #022e58;
    color: #f9fcff;
    transition: 0.3s ease-in-out;
  }

  ${mediaObj.mobile} {
    width: 100%;
  }
`;

const CardGrid = styled(motion.div)`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }

  & > div {
    border-radius: 16px;
  }
`;

interface CardProps {
  align: string;
  bg: string;
  gridArea: string;
  iconBg: string;
  pwidth: string;
}

const GridCard = styled(motion.div)<CardProps>`
  grid-area: ${({ gridArea }) => gridArea};
  background-color: ${({ bg }) => bg};

  padding: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 768px) {
    align-items: ${({ align }) => align};
  }

  & > div {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    background-color: ${({ iconBg }) => iconBg};

    display: grid;
    place-content: center;

    padding: 2.5rem;
  }

  & > h2 {
    margin: 12px 0;

    font-size: 1.5rem;
    font-weight: bold;

    text-align: left;

    /* @media screen and (min-width: 768px) {
      text-align: center;
    } */
  }

  & > p {
    text-align: left;

    @media screen and (min-width: 768px) {
      ${({ align }) => align === "center" && "text-align: center;"};
      width: 80%;
    }
  }
`;
