import React from "react";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import {
  ArrayWrapper,
  GroupWrapper,
  Info,
  InfoBold,
  SubTitle,
  VerticalFormWrapper,
  Wrapper,
} from "./styles";
import { FieldArray, Form, Formik } from "formik";
import styled from "styled-components";
import FormBtn from "../../components/FormBtn";
import RadioComp from "../../components/RadioComp";
import InputField from "../../components/formik fields/InputField";
import { processReviewData, RelationShipOptions } from "./data";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  initInput,
  getSelectedOption,
} from "../../components/data";
import { Modify, SingleOption } from "../../../../types/types";
import { saveDS160TravelCompanionsObj } from "../../../../api/ds160/types";
import {
  useGetDS160TravelCompanions,
  useSaveDS160TravelCompanions,
} from "../../../../hooks/ds160/usDS160";
import SelectField from "../../components/formik fields/SelectField";
import { TravelCompSectionValidation } from "./validation";
import GroupedSection from "./GroupedSection";
import AddAnotherBtn from "./AddAnotherBtn";
import { resetDynamicFields } from "../../components/data";
import useToNextSection from "../../../../hooks/useToNextSection";
import useAiReview from "./useAiReview";
import { ReviewTravelCompSection } from "./Review/components/data";

const initialNotGroupArray = {
  surname: "",
  givenName: "",
  relationshipwithPerson: null,
};

interface NotGroupArrayPropsPayload {
  surname: string;
  givenName: string;
  relationshipwithPerson: SingleOption | null;
}

export interface TravelCompSectionFormData
  extends Modify<
    Omit<
      saveDS160TravelCompanionsObj,
      "surname" | "givenName" | "relationshipwithPerson"
    >,
    {
      notGroupArray: NotGroupArrayPropsPayload[];
    }
  > {}

const TravelCompSection = () => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetDS160TravelCompanions();
  const { mutate, isLoading: isSaving } = useSaveDS160TravelCompanions();
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const savedNotGroupArray = () => {
    if (data?.surname || data?.givenName || data?.relationshipwithPerson) {
      const surnameData = data?.surname
        .split(",")
        .map((ev) => ({ surname: ev }));
      const givenNameData = data?.givenName
        .split(",")
        .map((ev) => ({ givenName: ev }));
      const relationshipData = data?.relationshipwithPerson
        .split(",")
        .map((ev) => ({
          relationshipwithPerson: getSelectedOption(RelationShipOptions, ev),
        }));

      return surnameData.map((ev, i) => ({
        ...ev,
        ...givenNameData[i],
        ...relationshipData[i],
      }));
    } else return [initialNotGroupArray];
  };

  const initialValues: TravelCompSectionFormData = {
    otherPersonsTravelling: initInput(data?.otherPersonsTravelling),
    travellingAsPartOfGroup: initInput(data?.travellingAsPartOfGroup),
    groupName: initInput(data?.groupName),
    notGroupArray: savedNotGroupArray(),
  };

  const onSubmit = (values: TravelCompSectionFormData) => {
    const { notGroupArray, ...rest } = values;

    const payload = {
      ...rest,
      surname: notGroupArray.map((ev) => ev.surname).join(","),
      givenName: notGroupArray.map((ev) => ev.givenName).join(","),
      relationshipwithPerson: notGroupArray
        .map((ev) => ev.relationshipwithPerson?.value || "")
        .join(","),
    };

    const section = ReviewTravelCompSection(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      travel: {
        ...riskData?.travel,
        ...result,
      },
    };

    const onSave = () => {
      mutate(payload, {
        onSuccess: toNextSection,
      });
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || riskLoading}
      isError={isError || riskError}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your travel
            companions.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={TravelCompSectionValidation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <Sub>Person Traveling with You</Sub>
              <FormWrapper>
                <RadioComp
                  title="Are there other persons traveling with you?"
                  name="otherPersonsTravelling"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "travellingAsPartOfGroup", value: "" },
                    ]);
                  }}
                />

                {formik.values.otherPersonsTravelling ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <RadioComp
                      title="Are you traveling as part of a group or organization?"
                      name="travellingAsPartOfGroup"
                      options={GenericYesNo}
                      onChange={() => {
                        resetDynamicFields(formik, [
                          {
                            name: "notGroupArray",
                            value: [initialNotGroupArray],
                          },
                          { name: "groupName", value: "" },
                        ]);
                      }}
                    />
                  </>
                )}

                {formik.values.travellingAsPartOfGroup ===
                  GenericYesNoOptionsValue.no && (
                  <>
                    <GroupWrapper>
                      <FieldArray
                        name="notGroupArray"
                        render={(arrayHelpers) => (
                          <ArrayWrapper>
                            {formik.values.notGroupArray.map((_, index) => (
                              <GroupedSection
                                key={index}
                                index={index}
                                onCancel={() => arrayHelpers.remove(index)}
                              >
                                <InputField
                                  name={`notGroupArray[${index}].surname`}
                                  label={`Surname of person traveling with you`}
                                  placeholder=""
                                />
                                <InputField
                                  name={`notGroupArray[${index}].givenName`}
                                  label={`Given name of person traveling with you`}
                                  placeholder=""
                                />
                                <SelectField
                                  name={`notGroupArray[${index}].relationshipwithPerson`}
                                  label={`Relationship with person`}
                                  placeholder="-Select-"
                                  options={RelationShipOptions}
                                />
                              </GroupedSection>
                            ))}

                            <AddAnotherBtn
                              onClick={() =>
                                arrayHelpers.push(initialNotGroupArray)
                              }
                            />
                          </ArrayWrapper>
                        )}
                      />
                    </GroupWrapper>
                  </>
                )}

                {formik.values.travellingAsPartOfGroup ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Enter group name"
                      placeholder=""
                      name="groupName"
                    />
                  </>
                )}
              </FormWrapper>
              <FormBtn
                isLoading={isSaving || riskSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default TravelCompSection;

const FormWrapper = styled(VerticalFormWrapper)`
  & > div {
    width: 50%;
  }
`;

const Sub = styled(SubTitle)`
  margin-bottom: 16px;
`;
