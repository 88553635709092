import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { RiSendPlaneFill } from "react-icons/ri";
import { GrMicrophone } from "react-icons/gr";
import Markdown from "markdown-to-jsx";

import { useSnapshot } from "valtio";
import { FaCircleNotch, FaCirclePlay, FaCircleStop } from "react-icons/fa6";
import {
  useDeleteInterviewHistory,
  useGetInterviewVoiceHistory,
} from "../../../../hooks/utils/useUtils";
import { userDataStore } from "../../../../store/userData";
import useIsLoggedIn from "../../../../hooks/useIsLoggedIn";
// import useGoogleTTS from "../../../../hooks/useGoogleTTS";
import Constaint from "../../layout/components/Constaint";
import Spinner from "../../../../utils/Spinner";
import FiBiAuth from "./FiBiAuth";
import AiDisclaimer from "../../components/AiDisclaimer";
import Colors from "../../../../styles/Colors";
import { saveInterview } from "../../../../api/ai";
import { toast } from "react-toastify";
import { VscDebugRestart } from "react-icons/vsc";
import useOpenAiTTS from "../../../../hooks/useOpenAiTTS";

enum MessageSender {
  user = "user",
  bot = "bot",
}

interface Message {
  message: string;
  sender: MessageSender;
}

const FiBiVoice: React.FC = () => {
  const { data: historyData, isFetching: historyLoading } =
    useGetInterviewVoiceHistory();
  const { mutate: deleteMutate, isLoading: deleteLoading } =
    useDeleteInterviewHistory();
  const { userData } = useSnapshot(userDataStore);
  // const [sessionId, setSessionId] = useState<string>("");
  const [input, setInput] = useState<string>("");
  const [chatHistory, setChatHistory] = useState<Message[]>([]);
  const [prevHistory, setPrevHistory] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  //   const [speakText, setSpeakText] = useState<string>();
  const isLoggedIn = useIsLoggedIn();
  // const { isLoading, audioUrl: synth, synthesize: speak } = useGoogleTTS();
  const {
    isLoading: openAiLoading,
    audioUrl: openAiAudioUrl,
    synthesize: openAiSpeak,
  } = useOpenAiTTS();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  //   const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [audioUrl, setAudioUrl] = useState<string>("");
  // const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const inputRef = useRef(input);

  useEffect(() => {
    inputRef.current = input;
  }, [input]);

  useEffect(() => {
    if (openAiAudioUrl) setAudioUrl(openAiAudioUrl);
  }, [openAiAudioUrl]);

  // useEffect(() => {
  //   console.log({ synth });
  // }, [isLoading, synth]);

  // useEffect(() => {
  //   console.log({ openAiAudioUrl });
  // }, [openAiLoading, openAiAudioUrl]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chatHistory.length]);

  const handlePlayAudio = useCallback(
    (text: string) => {
      stopPlayingAudio();
      openAiSpeak(text);
    },
    [openAiSpeak]
  );

  const recognition = useMemo(() => {
    if ("webkitSpeechRecognition" in window) {
      const rec = new (window as any).webkitSpeechRecognition();
      rec.continuous = true; // Set to true for continuous listening
      rec.interimResults = true; // Allow interim results
      rec.lang = "en-US";
      return rec;
    }
    return null;
  }, []);

  const sendMessage = useCallback(
    async (tempData?: string) => {
      if (!userData) return;
      const temp = tempData || inputRef.current; // Use ref to get the latest input
      if (temp.trim()) {
        const newMessage: Message = {
          message: temp.trim(),
          sender: MessageSender.user,
        };
        setChatHistory([...chatHistory, newMessage]);
        setInput("");
        setLoading(true);
        try {
          const result = await saveInterview({
            id: userData?.userInfo.userId,
            payload: {
              prompt: temp,
            },
          });

          const aiResponse = {
            sender: MessageSender.bot,
            message: result.data.result,
          };
          setChatHistory((prev) => [...prev, aiResponse]);

          // Immediately play the audio of the AI response
          handlePlayAudio(result.data.result);
        } catch (error) {
          const errorMessage = {
            sender: MessageSender.bot,
            message:
              "We're experiencing a temporary hiccup. Feel free to retry your request. If you still encounter problems, our support team at support@seamlessvisa.com is here for you.",
          };
          setChatHistory((prev) => [...prev, errorMessage]);
          console.error("Error generating response:", error);
        } finally {
          setLoading(false);
          setInput("");
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatHistory, userData] // looking fo a way to play the audio without cause a loop
  );

  const stopListening = useCallback(() => {
    if (recognition) {
      recognition.stop();
      setInput("");
      setIsListening(false);
    }
  }, [recognition]);

  const startListening = useCallback(() => {
    if (recognition) {
      let pauseTimer: NodeJS.Timeout | null = null;
      let finalTranscript = "";

      const resetPauseTimer = () => {
        if (pauseTimer) clearTimeout(pauseTimer);
        pauseTimer = setTimeout(() => {
          stopListening();
          sendMessage(finalTranscript);
        }, 2500);
      };

      recognition.onresult = (event: any) => {
        let interimTranscript = "";

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript;
          } else {
            interimTranscript += transcript;
          }
        }

        setInput(finalTranscript + interimTranscript); // Show both final and interim transcripts in the input field

        resetPauseTimer(); // Reset the pause timer after every speech result
      };

      recognition.onend = () => {
        setIsListening(false);
        if (pauseTimer) clearTimeout(pauseTimer); // Clear the timer when recognition ends
      };

      recognition.start();
      setIsListening(true);
    }
  }, [recognition, sendMessage, stopListening]);

  useEffect(() => {
    if (audioUrl.trim() !== "") {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }

      const newAudio = new Audio(audioUrl);
      audioRef.current = newAudio;

      console.log({ newAudio });

      newAudio.onended = () => {
        setIsSpeaking(false);
        startListening();
        setAudioUrl("");
      };

      newAudio.play().catch((error) => {
        toast.error("Error playing audio:", error);
        setIsSpeaking(false);
      });

      setIsSpeaking(true);
    }
  }, [audioUrl, startListening]);

  const stopPlayingAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsSpeaking(false);
    }
  };

  const onSendVoice = () => {
    if (recognition) {
      stopListening();
      sendMessage(input);
      setInput("");
    }
  };

  useEffect(() => {
    if (historyLoading || (historyData && historyData?.length > 0)) return;
    const fetch = async () => {
      setLoading(true);
      try {
        const result = await saveInterview({
          id: "",
          payload: {
            prompt: "",
          },
        });

        const aiResponse = {
          sender: MessageSender.bot,
          message: result.data.result,
        };
        // setSessionId(result.data.id);
        setChatHistory((prev) => [...prev, aiResponse]);
      } catch (error) {
        const errorMessage = {
          sender: MessageSender.bot,
          message:
            "We're experiencing a temporary hiccup. Feel free to retry your request. If you still encounter problems, our support team at connect@seamlessvisa.com is here for you.",
        };
        setChatHistory((prev) => [...prev, errorMessage]);
        console.error("Error generating response:", error);
      } finally {
        setLoading(false);
        setInput("");
      }
    };
    fetch();
  }, [historyData, historyLoading]);

  useEffect(() => {
    if (typeof historyData === "string") return;
    const prevTemp = [];

    const modelArray = historyData?.find((ev) => ev.role === "model");

    const userArrayTemp = historyData?.find((ev) => ev.role === "user");
    const userArray = {
      ...userArrayTemp,
      parts: userArrayTemp?.parts.filter((ev) => ev.text) || [],
    };
    const arrayLength = Math.max(
      modelArray?.parts.length || 0,
      userArray?.parts.length || 0
    );

    // const longerArray = modelArray?.parts.length === arrayLength ? modelArray?.parts : userArray?.parts.length

    for (let index = 0; index < arrayLength; index++) {
      const modelRes = modelArray?.parts[index]?.text;

      prevTemp.push({
        sender: MessageSender.bot,
        message: modelRes || "",
      });
      //
      const userRes = userArray?.parts[index]?.text;
      if (userRes) {
        prevTemp.push({
          sender: MessageSender.user,
          message: userRes || "",
        });
      }
    }

    setPrevHistory(prevTemp);
  }, [historyData]);

  const onRestart = () => {
    deleteMutate();
    setPrevHistory([]);
    setChatHistory([]);
  };

  const masterLoading = loading || deleteLoading;
  // loading || isListening || historyLoading || deleteLoading;

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <MiniContainer>
            {!isLoggedIn && <FiBiAuth />}

            <Body ref={chatContainerRef}>
              {(prevHistory.length > 0
                ? prevHistory.concat(chatHistory)
                : chatHistory
              ).map((message, index) => (
                <div
                  key={index}
                  className={`mb-4 flex ${
                    message.sender === "user" ? "justify-end" : ""
                  }`}
                >
                  <span
                    className={`inline-block p-2 rounded-3xl relative ${
                      message.sender === "user"
                        ? "inline-block max-w-[70%] px-4 py-3 rounded-tr-[4px] bg-[#0276E9] text-white"
                        : "bg-white shadow px-4 py-3 border border-gray-300 text-gray-600 rounded-tl-[4px] mb-4 max-w-[85%]"
                    }`}
                  >
                    <Markdown>{message.message}</Markdown>

                    {message.sender === MessageSender.bot && (
                      <button
                        disabled={openAiLoading}
                        onClick={() =>
                          isSpeaking
                            ? stopPlayingAudio()
                            : handlePlayAudio(message.message)
                        }
                        className={` absolute top-0 bg-gray-200 text-gray-500 p-2 rounded-full hover:bg-[#0276E9] duration-300 hover:text-white cursor-pointer right-[-2.54rem]`}
                      >
                        {openAiLoading ? (
                          <FaCircleNotch className="animate-spin" />
                        ) : isSpeaking ? (
                          <FaCircleStop />
                        ) : (
                          <FaCirclePlay />
                        )}
                      </button>
                    )}
                  </span>
                </div>
              ))}
            </Body>
            <Footer
              onSubmit={(e) => {
                e.preventDefault();
                sendMessage();
              }}
            >
              <Btn
                bg={"#fecaca"}
                hover="#dc2626"
                onClick={onRestart}
                disabled={deleteLoading}
              >
                {deleteLoading ? (
                  <Spinner size={24} color="white" />
                ) : (
                  <VscDebugRestart size={24} color="#dc2626" />
                )}
              </Btn>

              {isListening && (
                <div
                  role="button"
                  onClick={() => {
                    stopListening();
                  }}
                  className="h-[48px] aspect-square flex items-center justify-center border border-red-600 bg-red-100 text-red-600 rounded-[8px] hover:bg-red-600 hover:text-white duration-300 cursor-pointer"
                >
                  <FaCircleStop size={24} />
                </div>
              )}

              <button
                disabled={isSpeaking || isListening}
                onClick={() => {
                  if (!isListening) {
                    startListening();
                  } else if (isListening) {
                    onSendVoice();
                  }
                }}
                className={`h-[48px] aspect-square flex items-center justify-center bg-gray-200 rounded-[8px] disabled:cursor-not-allowed ${
                  isListening
                    ? "hover:bg-red-200 hover:text-red-600"
                    : isSpeaking
                    ? "hover:bg-blue-200"
                    : "hover:bg-[#0276E9] hover:text-white"
                } duration-150`}
              >
                <GrMicrophone
                  size={24}
                  color={
                    isListening ? "red" : isSpeaking ? "#b1b1b1" : undefined
                  }
                  className={`flex-shrink-0 ${
                    isListening ? "animate-pulse" : ""
                  }`}
                />
              </button>

              <Btn
                disabled={
                  !(!isListening ? !!input.trim() : true) || masterLoading
                }
                onClick={() => (isListening ? onSendVoice() : sendMessage())}
              >
                {masterLoading ? (
                  <Spinner size={24} color="white" />
                ) : (
                  <RiSendPlaneFill
                    color={
                      masterLoading ||
                      isSpeaking ||
                      !(!isListening ? !!input.trim() : true)
                        ? "#b1b1b1"
                        : "white"
                    }
                    size={24}
                  />
                )}
              </Btn>
            </Footer>
            <AiDisclaimer />
          </MiniContainer>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default FiBiVoice;

const Wrapper = styled.div`
  background-color: #f9fcff;
`;

const Container = styled.div`
  /* padding: 100px 0; */
  padding: 50px 0;
`;

const MiniContainer = styled.div`
  height: calc(100vh - 200px);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
  gap: 24px;
`;

const Body = styled.div`
  overflow-y: auto;
  border: 1px solid #b3bac1;
  border-radius: 8px;
  background-color: #f9fcff;
  padding: 24px;
`;

const Footer = styled.form`
  min-height: 56px;
  padding: 4px;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 12px;

  /* position: sticky;
  bottom: 0;
  left: 0; */
`;
interface BtnProps {
  bg?: string;
  hover?: string;
}

const Btn = styled.button<BtnProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bg }) => (bg ? bg : Colors.Blue00)};
  width: 48px;
  height: 48px;
  border-radius: 8px;
  flex-shrink: 0;
  transition: all 150ms linear;

  & > svg {
    transition: all 150ms linear;
  }

  &:hover {
    background-color: ${({ hover }) => (hover ? hover : Colors.BlueEA)};

    & > svg {
      color: white;
      fill: white;
    }
  }

  &:disabled {
    background-color: #e5e7eb;
    cursor: not-allowed;
  }
`;
