import React from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { FieldArray, Form, Formik } from "formik";
import FormBtn from "../../../components/FormBtn";
import {
  useGetDS160WETPrevious,
  useSaveDS160WETPrevious,
} from "../../../../../hooks/ds160/usDS160";
import { Dayjs } from "dayjs";
import { saveDS160WETPreviousObj } from "../../../../../api/ds160/types";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  resetDynamicFields,
  ToSaveDate,
  initInput,
  saveDynamicCheck,
  initDate,
  ExplicitNGDateFormat,
  formatMultiStates,
  SpecialSeparator,
} from "../../../components/data";
import { Modify, SingleOption } from "../../../../../types/types";
import RadioComp from "../../../components/RadioComp";
import { ArrayWrapper, GroupWrapper, VerticalFormWrapper } from "../styles";
import GroupedSection from "../GroupedSection";
import AddAnotherBtn from "../AddAnotherBtn";
import { WETPreviousValidation } from "../validation";
import useToNextSection from "../../../../../hooks/useToNextSection";
import PrevEmployment from "./PrevEmployment";
import PrevEducation from "./PrevEducation";
import useMultiStatesByCountry from "../../../form 400/components/useMultiStatesByCountry";
import { ReviewWETAdditionalSectionB } from "../Review/components/data";
import { processReviewData } from "../data";
import useAiReview from "../useAiReview";

const initialPrevEmpArray = {
  employerName: "",
  employerStreetAddressLine1: "",
  employerStreetAddressLine2: "",
  city: "",
  stateOrProvince: null,
  zipCode: "",
  phoneNumber: "",
  countryOrRegion: null,
  jobTitle: "",
  supervisorSurname: "",
  doesNotApplySurname: false,
  supervisorGivenNames: "",
  doesNotApplyGivenNames: false,
  employmentDateFrom: null,
  employmentDateTo: null,
  dutiesDescription: "",
};

const initialEduArray = {
  nameOfInstitute: "",
  instituteStreetAddressLine1: "",
  instituteStreetAddressLine2: "",
  instituteCity: "",
  instituteStateOrProvince: null,
  instituteZipCode: "",
  country: null,
  courseOfStudy: "",
  dateOfAttendanceFrom: null,
  dateOfAttendanceTo: null,
};

export interface PrevEmpArrayPropsPayload {
  employerName: string;
  employerStreetAddressLine1: string;
  employerStreetAddressLine2: string;
  city: string;
  stateOrProvince: SingleOption | null;
  zipCode: string;
  phoneNumber: string;
  countryOrRegion: SingleOption | null;
  jobTitle: string;
  supervisorSurname: string;
  doesNotApplySurname: boolean;
  supervisorGivenNames: string;
  doesNotApplyGivenNames: boolean;
  employmentDateFrom: Dayjs | null;
  employmentDateTo: Dayjs | null;
  dutiesDescription: string;
}

export interface EduArrayPropsPayload {
  nameOfInstitute: string;
  instituteStreetAddressLine1: string;
  instituteStreetAddressLine2: string;
  instituteCity: string;
  instituteStateOrProvince: SingleOption | null;
  instituteZipCode: string;
  country: SingleOption | null;
  courseOfStudy: string;
  dateOfAttendanceFrom: Dayjs | null;
  dateOfAttendanceTo: Dayjs | null;
}

export interface WETPreviousFormData
  extends Modify<
    Omit<
      saveDS160WETPreviousObj,
      | "employerName"
      | "employerStreetAddressLine1"
      | "employerStreetAddressLine2"
      | "city"
      | "stateOrProvince"
      | "zipCode"
      | "phoneNumber"
      | "countryOrRegion"
      | "jobTitle"
      | "supervisorSurname"
      | "doesNotApplySurname"
      | "supervisorGivenNames"
      | "doesNotApplyGivenNames"
      | "employmentDateFrom"
      | "employmentDateTo"
      | "dutiesDescription"
      | "nameOfInstitute"
      | "instituteStreetAddressLine1"
      | "instituteStreetAddressLine2"
      | "instituteCity"
      | "instituteStateOrProvince"
      | "instituteZipCode"
      | "country"
      | "courseOfStudy"
      | "dateOfAttendanceFrom"
      | "dateOfAttendanceTo"
    >,
    {
      PrevEmpArray: PrevEmpArrayPropsPayload[];
      EduArray: EduArrayPropsPayload[];
    }
  > {}

const WETPrevious = () => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetDS160WETPrevious();
  const { mutate, isLoading: isSaving } = useSaveDS160WETPrevious();
  const { statesArray: stateOrProvidenceArray } = useMultiStatesByCountry(
    data?.countryOrRegion
  );
  const { statesArray: instituteStateOrProvidenceArray } =
    useMultiStatesByCountry(data?.country);
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const savedPrevEmpArray = () => {
    if (data?.employerName) {
      const employerNameData = data?.employerName
        .split(",")
        .map((ev) => ({ employerName: ev }));

      const employerStreetAddressLine1Data = data?.employerStreetAddressLine1
        .split(SpecialSeparator)
        .map((ev) => ({ employerStreetAddressLine1: ev }));

      const employerStreetAddressLine2Data = data?.employerStreetAddressLine2
        .split(SpecialSeparator)
        .map((ev) => ({ employerStreetAddressLine2: ev }));

      const cityData = data?.city.split(",").map((ev) => ({ city: ev }));

      const zipCodeData = data?.zipCode
        .split(",")
        .map((ev) => ({ zipCode: ev }));

      const phoneNumberData = data?.phoneNumber
        .split(",")
        .map((ev) => ({ phoneNumber: ev }));

      // permanant patch for now
      const countryOrRegionData = data?.countryOrRegion
        .split(",")
        .map((ev) => ({
          countryOrRegion: { value: ev, label: ev },
        }));

      // permanant patch for now
      const stateOrProvinceData = formatMultiStates({
        dataKey: data?.stateOrProvince,
        fieldKey: "stateOrProvince",
        dataArray: stateOrProvidenceArray,
      }) as { stateOrProvince: SingleOption }[];

      const jobTitleData = data?.jobTitle
        .split(",")
        .map((ev) => ({ jobTitle: ev }));

      const supervisorSurnameData = data?.supervisorSurname
        .split(",")
        .map((ev) => ({ supervisorSurname: ev }));

      const doesNotApplySurnameData = data?.doesNotApplySurname
        .split(",")
        .map((ev) => ({ doesNotApplySurname: saveDynamicCheck(ev) }));

      const supervisorGivenNamesData = data?.supervisorGivenNames
        .split(",")
        .map((ev) => ({ supervisorGivenNames: ev }));

      const doesNotApplyGivenNamesData = data?.doesNotApplyGivenNames
        .split(",")
        .map((ev) => ({
          doesNotApplyGivenNames: saveDynamicCheck(ev),
        }));

      const employmentDateFromData = data?.employmentDateFrom
        .split(",")
        .map((ev) => ({
          employmentDateFrom: initDate(ev, ExplicitNGDateFormat),
        }));

      const employmentDateToData = data?.employmentDateTo
        .split(",")
        .map((ev) => ({
          employmentDateTo: initDate(ev, ExplicitNGDateFormat),
        }));

      const dutiesDescriptionData = data?.dutiesDescription
        .split(",")
        .map((ev) => ({ dutiesDescription: ev }));

      return employerNameData.map((ev, i) => ({
        ...ev,
        ...employerStreetAddressLine1Data[i],
        ...employerStreetAddressLine2Data[i],
        ...cityData[i],
        ...stateOrProvinceData[i],
        ...zipCodeData[i],
        ...phoneNumberData[i],
        ...countryOrRegionData[i],
        ...jobTitleData[i],
        ...supervisorSurnameData[i],
        ...doesNotApplySurnameData[i],
        ...supervisorGivenNamesData[i],
        ...doesNotApplyGivenNamesData[i],
        ...employmentDateFromData[i],
        ...employmentDateToData[i],
        ...dutiesDescriptionData[i],
      }));
    } else return [initialPrevEmpArray];
  };

  const savedEduArray = () => {
    if (data?.nameOfInstitute) {
      const nameOfInstituteData = data?.nameOfInstitute
        .split(",")
        .map((ev) => ({ nameOfInstitute: ev }));

      const instituteStreetAddressLine1Data = data?.instituteStreetAddressLine1
        .split(",")
        .map((ev) => ({ instituteStreetAddressLine1: ev }));

      const instituteStreetAddressLine2Data = data?.instituteStreetAddressLine2
        .split(",")
        .map((ev) => ({ instituteStreetAddressLine2: ev }));

      const instituteCityData = data?.instituteCity
        .split(",")
        .map((ev) => ({ instituteCity: ev }));

      const instituteZipCodeData = data?.instituteZipCode
        .split(",")
        .map((ev) => ({ instituteZipCode: ev }));

      // permanent patch for now
      const countryData = data?.country.split(",").map((ev) => ({
        country: { value: ev, label: ev },
      }));

      // permanent patch for now
      const instituteStateOrProvinceData = formatMultiStates({
        dataKey: data?.instituteStateOrProvince,
        fieldKey: "instituteStateOrProvince",
        dataArray: instituteStateOrProvidenceArray,
      }) as { instituteStateOrProvince: SingleOption }[];

      const courseOfStudyData = data?.courseOfStudy.split(",").map((ev) => ({
        courseOfStudy: ev,
      }));

      const dateOfAttendanceFromData = data?.dateOfAttendanceFrom
        .split(",")
        .map((ev) => ({
          dateOfAttendanceFrom: initDate(ev, ExplicitNGDateFormat),
        }));

      const dateOfAttendanceToData = data?.dateOfAttendanceTo
        .split(",")
        .map((ev) => ({
          dateOfAttendanceTo: initDate(ev, ExplicitNGDateFormat),
        }));

      return nameOfInstituteData.map((ev, i) => ({
        ...ev,
        ...instituteStreetAddressLine1Data[i],
        ...instituteStreetAddressLine2Data[i],
        ...instituteCityData[i],
        ...instituteStateOrProvinceData[i],
        ...instituteZipCodeData[i],
        ...countryData[i],
        ...courseOfStudyData[i],
        ...dateOfAttendanceFromData[i],
        ...dateOfAttendanceToData[i],
      }));
    } else return [initialEduArray];
  };

  const initialValues: WETPreviousFormData = {
    wereYouPreviouslyEmployed: initInput(data?.wereYouPreviouslyEmployed),
    PrevEmpArray: savedPrevEmpArray(),
    attendedAnyEduInstitution: initInput(data?.attendedAnyEduInstitution),
    EduArray: savedEduArray(),
  };

  const onSubmit = (values: WETPreviousFormData) => {
    const { PrevEmpArray, EduArray, ...rest } = values;

    const payload = {
      ...rest,
      employerName: PrevEmpArray.map((ev) => ev.employerName).join(","),
      employerStreetAddressLine1: PrevEmpArray.map(
        (ev) => ev.employerStreetAddressLine1
      ).join(SpecialSeparator),
      employerStreetAddressLine2: PrevEmpArray.map(
        (ev) => ev.employerStreetAddressLine2
      ).join(SpecialSeparator),
      city: PrevEmpArray.map((ev) => ev.city).join(","),
      stateOrProvince: PrevEmpArray.map(
        (ev) => ev.stateOrProvince?.value || ""
      ).join(","),
      zipCode: PrevEmpArray.map((ev) => ev.zipCode).join(","),
      phoneNumber: PrevEmpArray.map((ev) => ev.phoneNumber).join(","),
      countryOrRegion: PrevEmpArray.map(
        (ev) => ev.countryOrRegion?.value || ""
      ).join(","),
      jobTitle: PrevEmpArray.map((ev) => ev.jobTitle).join(","),
      supervisorSurname: PrevEmpArray.map((ev) => ev.supervisorSurname).join(
        ","
      ),
      doesNotApplySurname: PrevEmpArray.map(
        (ev) => ev.doesNotApplySurname
      ).join(","),
      supervisorGivenNames: PrevEmpArray.map(
        (ev) => ev.supervisorGivenNames
      ).join(","),
      doesNotApplyGivenNames: PrevEmpArray.map(
        (ev) => ev.doesNotApplyGivenNames
      ).join(","),
      employmentDateFrom: PrevEmpArray.map((ev) =>
        ToSaveDate(ev.employmentDateFrom, ExplicitNGDateFormat)
      ).join(","),
      employmentDateTo: PrevEmpArray.map((ev) =>
        ToSaveDate(ev.employmentDateTo, ExplicitNGDateFormat)
      ).join(","),
      dutiesDescription: PrevEmpArray.map((ev) => ev.dutiesDescription).join(
        ","
      ),
      nameOfInstitute: EduArray.map((ev) => ev.nameOfInstitute).join(","),
      instituteStreetAddressLine1: EduArray.map(
        (ev) => ev.instituteStreetAddressLine1
      ).join(","),
      instituteStreetAddressLine2: EduArray.map(
        (ev) => ev.instituteStreetAddressLine2
      ).join(","),
      instituteCity: EduArray.map((ev) => ev.instituteCity).join(","),
      instituteStateOrProvince: EduArray.map(
        (ev) => ev.instituteStateOrProvince?.value || ""
      ).join(","),
      instituteZipCode: EduArray.map((ev) => ev.instituteZipCode).join(","),
      country: EduArray.map((ev) => ev.country?.value || "").join(","),
      courseOfStudy: EduArray.map((ev) => ev.courseOfStudy).join(","),
      dateOfAttendanceFrom: EduArray.map((ev) =>
        ToSaveDate(ev.dateOfAttendanceFrom, ExplicitNGDateFormat)
      ).join(","),
      dateOfAttendanceTo: EduArray.map((ev) =>
        ToSaveDate(ev.dateOfAttendanceTo, ExplicitNGDateFormat)
      ).join(","),
    };

    const section = ReviewWETAdditionalSectionB(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      wet: {
        ...riskData?.wet,
        ...result,
      },
    };

    const onSave = () => {
      mutate(payload, {
        onSuccess: toNextSection,
      });
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || riskLoading}
      isError={isError || riskError}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your
            Work/Education/Training.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={WETPreviousValidation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <RadioComp
                  title="Were you previously employed?"
                  name="wereYouPreviouslyEmployed"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "PrevEmpArray", value: [initialPrevEmpArray] },
                    ]);
                  }}
                />

                {/* Yes */}
                {formik.values.wereYouPreviouslyEmployed ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <GroupWrapper>
                      <FieldArray
                        name="PrevEmpArray"
                        render={(arrayHelpers) => (
                          <ArrayWrapper>
                            {formik.values.PrevEmpArray.map((_, index) => (
                              <GroupedSection
                                key={index}
                                index={index}
                                onCancel={() => arrayHelpers.remove(index)}
                              >
                                <PrevEmployment
                                  data={data}
                                  formik={formik}
                                  index={index}
                                />
                              </GroupedSection>
                            ))}

                            <AddAnotherBtn
                              onClick={() =>
                                arrayHelpers.push(initialPrevEmpArray)
                              }
                            />
                          </ArrayWrapper>
                        )}
                      />
                    </GroupWrapper>

                    {/* end of first section Yes */}
                  </>
                )}

                <RadioComp
                  title="Have you attended any educational institutions at a
                    secondary level or above?"
                  name="attendedAnyEduInstitution"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "EduArray", value: [initialEduArray] },
                    ]);
                  }}
                />

                {/* if yes */}
                {formik.values.attendedAnyEduInstitution ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <GroupWrapper>
                      <FieldArray
                        name="EduArray"
                        render={(arrayHelpers) => (
                          <ArrayWrapper>
                            {formik.values.EduArray.map((_, index) => (
                              <GroupedSection
                                key={index}
                                index={index}
                                onCancel={() => arrayHelpers.remove(index)}
                              >
                                <PrevEducation
                                  data={data}
                                  formik={formik}
                                  index={index}
                                />
                              </GroupedSection>
                            ))}

                            <AddAnotherBtn
                              onClick={() => arrayHelpers.push(initialEduArray)}
                            />
                          </ArrayWrapper>
                        )}
                      />
                    </GroupWrapper>
                  </>
                )}
                <FormBtn
                  isLoading={isSaving || riskSaving}
                  addBackBtn
                  isError={formik.dirty && !formik.isValid}
                />
              </FormWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default WETPrevious;

const Wrapper = styled.div``;

const Info = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  margin-bottom: 30px;
`;

const InfoBold = styled.div`
  font-weight: 700;
`;

const FormWrapper = styled(VerticalFormWrapper)`
  & > div {
    width: 50%;
  }
`;
