import React from "react";
// import Hero from "./components/Hero";
// import Why from "./components/Why";
// import HowItWorks from "./components/HowItWorks";
import Pricing from "./components/Pricing";
import Cta from "./components/Cta";
import Banner from "./components/Banner";
import ToHelp from "./components/ToHelp";
import Dashboard from "./components/Dashboard";

const BusinessService = () => {
  return (
    <>
      {/* <Hero /> */}
      <Banner />
      {/* <Why /> */}
      <ToHelp />
      {/* <HowItWorks /> */}
      <Dashboard />
      <Pricing />
      <Cta />
    </>
  );
};

export default BusinessService;
