import React from "react";
import styled from "styled-components";
import Constaint from "../../../layout/components/Constaint";
import dashboardImage from "../../../../../images/web/dashboard.png";

const Dashboard = () => {
  return (
    <Wrapper>
      <Constaint>
        <Content>
          <Header>
            <h2>Your Customized Dashboard</h2>
            <p>
              Your dashboard provides instant access to all your clients' visa
              applications and their progress. With easy-to-understand reports
              and real-time updates, you'll always be in control and track
              applications effortlessly.
            </p>
            {/* <p>Stay informed and in control with our real-time immigration case management dashboard. Track the progress of your cases, receive instant updates on key milestones, and make data-driven decisions to optimize your immigration strategy.</p> */}
          </Header>
          <Body>
            <img src={dashboardImage} alt="sv-dashboard" />
          </Body>
        </Content>
      </Constaint>
    </Wrapper>
  );
};

export default Dashboard;

const Wrapper = styled.div`
  margin: 8rem 0;
`;
const Content = styled.div``;
const Header = styled.div`
  width: 100%;
  margin-inline: auto;
  text-align: center;

  @media screen and (min-width: 768px) {
    width: 70%;
  }

  & > h2 {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 1.5rem;
  }

  & > p {
    font-size: 1rem;
    margin-bottom: 3rem;
  }
`;
const Body = styled.div`
  & > img {
    width: 100%;
    box-shadow: 4px 4px 18px 24px #dbeafe;
    border-radius: 40px;
  }
`;
