import React, { FC } from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import InputField from "../../../components/formik fields/InputField";
import FormBtn from "../../../components/FormBtn";
import SelectField from "../../../components/formik fields/SelectField";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  ToSaveDate,
  initInput,
  getSelectedOption,
  initDate,
  ExplicitNGDateFormat,
} from "../../../components/data";
import { Modify, SingleOption } from "../../../../../types/types";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import {
  useGetDS160WETAdditional,
  useSaveDS160WETAdditional,
} from "../../../../../hooks/ds160/usDS160";
import { Dayjs } from "dayjs";
import {
  DS160EligibilityPayload,
  saveDS160WETAdditionalObj,
} from "../../../../../api/ds160/types";
import RadioComp from "../../../components/RadioComp";
import { ArrayWrapper, GroupWrapper, HalfFormWrapper } from "../styles";
import GroupedSection from "../GroupedSection";
import AddAnotherBtn from "../AddAnotherBtn";
import { WETAdditionalValidation } from "../validation";
import { resetDynamicFields } from "../../../components/data";
import useCountries from "../../../components/useCountries";
import useToNextSection from "../../../../../hooks/useToNextSection";
import { TooltipProps, TooltipType } from "../../../components/Tooltip";
import {
  processReviewData,
  WETATravelledLastFiveYearsCondition,
} from "../data";
import useAiReview from "../useAiReview";
import { ReviewWETAdditionalSectionC } from "../Review/components/data";

const initialLangArray = {
  languages: "",
};

const initialCountryArray = {
  countryOrRegion: null,
};

const initialOrgArray = {
  organizationName: "",
};

const initialMilitaryArray = {
  militaryCountryOrRegion: null,
  branchOfService: "",
  rank: "",
  militarySpecialty: "",
  dateOfServiceFrom: null,
  dateOfServiceTo: null,
};

interface LangArrayPropsPayload {
  languages: string;
}

interface CountryArrayPropsPayload {
  countryOrRegion: SingleOption | null;
}

interface OrgArrayPropsPayload {
  organizationName: string;
}

export interface MilitaryArrayPropsPayload {
  militaryCountryOrRegion: SingleOption | null;
  branchOfService: string;
  rank: string;
  militarySpecialty: string;
  dateOfServiceFrom: Dayjs | null;
  dateOfServiceTo: Dayjs | null;
}

export interface WETAdditionalFormData
  extends Modify<
    Omit<
      saveDS160WETAdditionalObj,
      | "languages"
      | "countryOrRegion"
      | "organizationName"
      | "militaryCountryOrRegion"
      | "branchOfService"
      | "rank"
      | "militarySpecialty"
      | "dateOfServiceFrom"
      | "dateOfServiceTo"
    >,
    {
      LangArray: LangArrayPropsPayload[];
      CountryArray: CountryArrayPropsPayload[];
      OrgArray: OrgArrayPropsPayload[];
      MilitaryArray: MilitaryArrayPropsPayload[];
      travelToCountriesOrRegions_risky_checkbox: boolean;
    }
  > {}

interface Props {
  formEligibility: DS160EligibilityPayload;
}

const WETAdditional: FC<Props> = ({ formEligibility }) => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetDS160WETAdditional();
  const { mutate, isLoading: isSaving } = useSaveDS160WETAdditional();
  const { formattedCountryData, countryLoading } = useCountries();
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const savedLangArray = () => {
    if (data?.languages) {
      return data?.languages.split(",").map((ev) => ({ languages: ev }));
    } else return [initialLangArray];
  };

  const savedCountryArray = () => {
    if (data?.countryOrRegion) {
      return data?.countryOrRegion.split(",").map((ev) => ({
        countryOrRegion: getSelectedOption(formattedCountryData || [], ev),
      }));
    } else return [initialCountryArray];
  };

  const savedOrgArray = () => {
    if (data?.organizationName) {
      return data?.organizationName
        .split(",")
        .map((ev) => ({ organizationName: ev }));
    } else return [initialOrgArray];
  };

  const savedMilitaryArray = () => {
    if (
      data?.militaryCountryOrRegion ||
      data?.branchOfService ||
      data?.rank ||
      data?.militarySpecialty ||
      data?.dateOfServiceFrom ||
      data?.dateOfServiceTo
    ) {
      const militaryCountryData = data?.militaryCountryOrRegion
        .split(",")
        .map((ev) => ({
          militaryCountryOrRegion: getSelectedOption(
            formattedCountryData || [],
            ev
          ),
        }));

      const branchOfServiceData = data?.branchOfService
        .split(",")
        .map((ev) => ({ branchOfService: ev }));

      const rankData = data?.rank.split(",").map((ev) => ({ rank: ev }));

      const militarySpecialtyData = data?.militarySpecialty
        .split(",")
        .map((ev) => ({ militarySpecialty: ev }));

      const dateOfServiceFromData = data?.dateOfServiceFrom
        .split(",")
        .map((ev) => ({
          dateOfServiceFrom: initDate(ev, ExplicitNGDateFormat),
        }));

      const dateOfServiceToData = data?.dateOfServiceTo
        .split(",")
        .map((ev) => ({ dateOfServiceTo: initDate(ev, ExplicitNGDateFormat) }));

      return militaryCountryData.map((ev, i) => ({
        ...ev,
        ...branchOfServiceData[i],
        ...rankData[i],
        ...militarySpecialtyData[i],
        ...dateOfServiceFromData[i],
        ...dateOfServiceToData[i],
      }));
    } else return [initialMilitaryArray];
  };

  const initialValues: WETAdditionalFormData = {
    belongToClanOrTribe: initInput(data?.belongToClanOrTribe),
    travelToCountriesOrRegions: initInput(data?.travelToCountriesOrRegions),
    belongToCharitableOrg: initInput(data?.belongToCharitableOrg),
    anySpecialisedSkills: initInput(data?.anySpecialisedSkills),
    everServedInTheMilitary: initInput(data?.everServedInTheMilitary),
    tribeName: initInput(data?.tribeName),
    LangArray: savedLangArray(),
    CountryArray: savedCountryArray(),
    OrgArray: savedOrgArray(),
    skillExplanation: initInput(data?.skillExplanation),
    MilitaryArray: savedMilitaryArray(),
    travelToCountriesOrRegions_risky_checkbox: false,
  };
  const onSubmit = (values: WETAdditionalFormData) => {
    const { LangArray, CountryArray, OrgArray, MilitaryArray, ...rest } =
      values;

    const payload = {
      ...rest,
      languages: LangArray.map((ev) => ev.languages).join(","),
      countryOrRegion: CountryArray.map(
        (ev) => ev.countryOrRegion?.value || ""
      ).join(","),
      organizationName: OrgArray.map((ev) => ev.organizationName).join(","),
      militaryCountryOrRegion: MilitaryArray.map(
        (ev) => ev.militaryCountryOrRegion?.value || ""
      ).join(","),
      branchOfService: MilitaryArray.map((ev) => ev.branchOfService).join(","),
      rank: MilitaryArray.map((ev) => ev.rank).join(","),
      militarySpecialty: MilitaryArray.map((ev) => ev.militarySpecialty).join(
        ","
      ),
      dateOfServiceFrom: MilitaryArray.map((ev) =>
        ToSaveDate(ev.dateOfServiceFrom, ExplicitNGDateFormat)
      ).join(","),
      dateOfServiceTo: MilitaryArray.map((ev) =>
        ToSaveDate(ev.dateOfServiceTo, ExplicitNGDateFormat)
      ).join(","),
    };

    const section = ReviewWETAdditionalSectionC(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      wet: {
        ...riskData?.wet,
        ...result,
      },
    };

    const onSave = () => {
      mutate(payload, {
        onSuccess: toNextSection,
      });
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  const getEligibilityValidation = (
    formik: FormikProps<WETAdditionalFormData>,
    name: string
  ): TooltipProps | undefined => {
    if (name === "travelToCountriesOrRegions") {
      if (WETATravelledLastFiveYearsCondition(formik.values, formEligibility))
        return {
          isReversed: true,
          type: TooltipType.risky,
        };
    }
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || riskLoading}
      isError={isError || riskError}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your
            Work/Education/Training.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={WETAdditionalValidation(formEligibility)}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <HalfFormWrapper>
                <RadioComp
                  title="Do you belong to a Clan or Tribe?"
                  name="belongToClanOrTribe"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "tribeName", value: "" },
                      { name: "LangArray", value: [initialLangArray] },
                    ]);
                  }}
                />

                {formik.values.belongToClanOrTribe ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Clan or Tribe Name"
                      placeholder=""
                      name="tribeName"
                    />

                    <GroupWrapper>
                      <FieldArray
                        name="LangArray"
                        render={(arrayHelpers) => (
                          <SectionGrouper>
                            {formik.values.LangArray.map((_, index) => (
                              <GroupedSection
                                key={index}
                                index={index}
                                onCancel={() => arrayHelpers.remove(index)}
                              >
                                <InputField
                                  name={`LangArray[${index}].languages`}
                                  label={`Provide a list of the Languages you speak`}
                                  placeholder=""
                                />
                              </GroupedSection>
                            ))}

                            <AddAnotherBtn
                              onClick={() =>
                                arrayHelpers.push(initialLangArray)
                              }
                            />
                          </SectionGrouper>
                        )}
                      />
                    </GroupWrapper>
                  </>
                )}

                <RadioComp
                  title="Have you traveled to any countries/region within the last 5years?"
                  name="travelToCountriesOrRegions"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "CountryArray", value: [initialCountryArray] },
                    ]);
                  }}
                  toolTipProps={getEligibilityValidation(
                    formik,
                    "travelToCountriesOrRegions"
                  )}
                />

                {formik.values.travelToCountriesOrRegions ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <GroupWrapper>
                      <FieldArray
                        name="CountryArray"
                        render={(arrayHelpers) => (
                          <SectionGrouper>
                            {formik.values.CountryArray.map((_, index) => (
                              <GroupedSection
                                key={index}
                                index={index}
                                onCancel={() => arrayHelpers.remove(index)}
                              >
                                <SelectField
                                  name={`CountryArray[${index}].countryOrRegion`}
                                  label="Country/Region"
                                  placeholder="-Select-"
                                  options={formattedCountryData}
                                  isLoading={countryLoading}
                                />
                              </GroupedSection>
                            ))}

                            <AddAnotherBtn
                              onClick={() =>
                                arrayHelpers.push(initialCountryArray)
                              }
                            />
                          </SectionGrouper>
                        )}
                      />
                    </GroupWrapper>
                  </>
                )}

                <RadioComp
                  title="Have you ever belonged to, contributed to, or worked for any
                    professional, social or charitable organization?"
                  name="belongToCharitableOrg"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "OrgArray", value: [initialOrgArray] },
                    ]);
                  }}
                />

                {formik.values.belongToCharitableOrg ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <GroupWrapper>
                      <FieldArray
                        name="OrgArray"
                        render={(arrayHelpers) => (
                          <SectionGrouper>
                            {formik.values.OrgArray.map((_, index) => (
                              <GroupedSection
                                key={index}
                                index={index}
                                onCancel={() => arrayHelpers.remove(index)}
                              >
                                <InputField
                                  name={`OrgArray[${index}].organizationName`}
                                  label="Organisation Name"
                                  placeholder=""
                                />
                              </GroupedSection>
                            ))}

                            <AddAnotherBtn
                              onClick={() => arrayHelpers.push(initialOrgArray)}
                            />
                          </SectionGrouper>
                        )}
                      />
                    </GroupWrapper>
                  </>
                )}

                <RadioComp
                  title="Do you have any specialised skills or training such as
                    firearms explosives no clear biological or chemical
                    experience?"
                  name="anySpecialisedSkills"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "skillExplanation", value: "" },
                    ]);
                  }}
                />

                {formik.values.anySpecialisedSkills ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Other Status? explain."
                      placeholder="Explanation..."
                      name="skillExplanation"
                      inputType="text-area"
                    />
                  </>
                )}

                <RadioComp
                  title="Have you ever served in the military?"
                  name="everServedInTheMilitary"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "MilitaryArray", value: [initialMilitaryArray] },
                    ]);
                  }}
                />

                {formik.values.everServedInTheMilitary ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <GroupWrapper>
                      <FieldArray
                        name="MilitaryArray"
                        render={(arrayHelpers) => (
                          <SectionGrouper>
                            {formik.values.MilitaryArray.map((_, index) => (
                              <GroupedSection
                                key={index}
                                index={index}
                                onCancel={() => arrayHelpers.remove(index)}
                              >
                                <SelectField
                                  name={`MilitaryArray[${index}].militaryCountryOrRegion`}
                                  label="Name of Country/Region"
                                  placeholder="-Select-"
                                  options={formattedCountryData}
                                  isLoading={countryLoading}
                                />

                                <InputField
                                  name={`MilitaryArray[${index}].branchOfService`}
                                  label="Branch Of Service"
                                  placeholder=""
                                />

                                <InputField
                                  name={`MilitaryArray[${index}].rank`}
                                  label="Rank/Position"
                                  placeholder=""
                                />

                                <InputField
                                  name={`MilitaryArray[${index}].militarySpecialty`}
                                  label="Military Specialty"
                                  placeholder=""
                                />

                                <DatePickerField
                                  name={`MilitaryArray[${index}].dateOfServiceFrom`}
                                  label="Date of Service From"
                                  placeholder={ExplicitNGDateFormat}
                                  disableFuture={true}
                                />

                                <DatePickerField
                                  name={`MilitaryArray[${index}].dateOfServiceTo`}
                                  label="Date of Service to"
                                  placeholder={ExplicitNGDateFormat}
                                  disableFuture={true}
                                />
                              </GroupedSection>
                            ))}

                            <AddAnotherBtn
                              onClick={() =>
                                arrayHelpers.push(initialMilitaryArray)
                              }
                            />
                          </SectionGrouper>
                        )}
                      />
                    </GroupWrapper>
                  </>
                )}
                <FormBtn
                  isLoading={isSaving || riskSaving}
                  addBackBtn
                  isError={formik.dirty && !formik.isValid}
                />
              </HalfFormWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default WETAdditional;

const Wrapper = styled.div``;

const Info = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  margin-bottom: 30px;
`;

const InfoBold = styled.div`
  font-weight: 700;
`;

const SectionGrouper = styled(ArrayWrapper)`
  gap: 10px;
`;
