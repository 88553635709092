import React from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik } from "formik";
import InputField from "../../../components/formik fields/InputField";
import FormBtn from "../../../components/FormBtn";
import {
  useGetDS160SecurityBackgroundP1,
  useSaveDS160SecurityBackgroundP1,
} from "../../../../../hooks/ds160/usDS160";
import { saveDS160SecurityBackgroundP1Obj } from "../../../../../api/ds160/types";
import RadioComp from "../../../components/RadioComp";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
} from "../../../components/data";
import { SecurityBackgroundP1Validation } from "../validation";
import { HalfFormWrapper } from "../styles";
import { resetDynamicFields } from "../../../components/data";
import useToNextSection from "../../../../../hooks/useToNextSection";
import { getSecurityBackgroundValidations, processReviewData } from "../data";
import useAiReview from "../useAiReview";
import { ReviewSecuritySectionA } from "../Review/components/data";

const SecurityBackgroundP1 = () => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetDS160SecurityBackgroundP1();
  const { mutate, isLoading: isSaving } = useSaveDS160SecurityBackgroundP1();
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const onSubmit = (values: saveDS160SecurityBackgroundP1Obj) => {
    const payload = values;
    const section = ReviewSecuritySectionA(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      security: {
        ...riskData?.security,
        ...result,
      },
    };

    const onSave = () => {
      mutate(payload, {
        onSuccess: toNextSection,
      });
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  const initialValues: saveDS160SecurityBackgroundP1Obj = {
    partOneI: data?.partOneI || "",
    explanationPartOneI: data?.explanationPartOneI || "",
    partOneII: data?.partOneII || "",
    explanationPartOneII: data?.explanationPartOneII || "",
    partOneIII: data?.partOneIII || "",
    explanationPartOneIII: data?.explanationPartOneIII || "",
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || riskLoading}
      isError={isError || riskError}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your Security and
            Background.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={SecurityBackgroundP1Validation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <HalfFormWrapper>
                <RadioComp
                  title="Do you have a communicable disease of public health
                  significance communicable diseases of public significance
                  include chancroid, gonorrhea, granuloma inguinale, infectious
                  leprosy, lymphogranuloma venereum, infectious stage syphilis,
                  active tuberculosis, and other diseases as determined by the
                  Department of health and human services?"
                  name="partOneI"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partOneI"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartOneI", value: "" },
                    ]);
                  }}
                />

                {formik.values.partOneI === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartOneI"
                      inputType="text-area"
                    />
                  </>
                )}

                <RadioComp
                  title="Do you have a mental or physical disorder that processes or
                    is likely to pose a threat to the safety or welfare of
                    yourself or others?"
                  name="partOneII"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partOneII"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartOneII", value: "" },
                    ]);
                  }}
                />

                {formik.values.partOneII === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartOneII"
                      inputType="text-area"
                    />
                  </>
                )}

                <RadioComp
                  title="Are you or have you ever been a drug abuser or addict?"
                  name="partOneIII"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partOneIII"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartOneIII", value: "" },
                    ]);
                  }}
                />

                {formik.values.partOneIII === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartOneIII"
                      inputType="text-area"
                    />
                  </>
                )}
                <FormBtn
                  isLoading={isSaving || riskSaving}
                  addBackBtn
                  isError={formik.dirty && !formik.isValid}
                />
              </HalfFormWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default SecurityBackgroundP1;

const Wrapper = styled.div``;

const Info = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  margin-bottom: 30px;
`;

const InfoBold = styled.div`
  font-weight: 700;
`;
