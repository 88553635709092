import { useNavigate, useSearchParams } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import Verified from "./Verified";
import useIsLoggedIn from "../../../../hooks/useIsLoggedIn";
import { Form, Formik, FormikHelpers } from "formik";
import { useSignup } from "../../../../hooks/auth/useAuth";
import Spinner from "../../../../utils/Spinner";
import AuthRightSession from "./AuthRightSession";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import InputField from "../../components/formik fields/InputField";
import { SignUpValidation } from "./validation";
import EmailNotif from "./EmailNotif";
import PhoneFormatter from "../../components/formik fields/PhoneFormatter";

interface FormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  confirmPassword: string;
  agreement: boolean;
  isAgent: boolean;
}

const Join = () => {
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const [openEmailNotif, setEmailNotif] = useState(false);
  // const [refCode, setRefCode] = useState<string | null>("")

  const { mutate, isLoading } = useSignup();

  useEffect(() => {
    if (isLoggedIn) navigate(WebPaths.Profile);
  }, [isLoggedIn, navigate]);

  // Account verification
  const [searchParams] = useSearchParams();

  const [isVerified, setIsVerified] = useState<string | boolean | null>(
    searchParams.get("verfication")
  );

  const ref = searchParams.get("ref");
  const [userID] = useState<string | null>(searchParams.get("user"));

  const onCloseModal = () => {
    setIsVerified(false);
  };

  const onSubmit = (
    values: FormData,
    { resetForm }: FormikHelpers<FormData>
  ) => {
    const { firstName, lastName, email, password, phoneNumber, isAgent } =
      values;
    const payload = {
      name: firstName + " " + lastName,
      phoneNumber: phoneNumber,
      email: email,
      password: password,
      isAgent,
    };

    mutate(
      { data: payload, refCode: ref },
      {
        onSuccess: () => {
          setEmailNotif(true);
          resetForm();
        },
      }
    );
  };

  return (
    <div className="flex items-center justify-center">
      <div className="flex mx-auto">
        <div className="p-5 sm:p-16">
          <h2 className="mb-8 text-xl font-semibold text-center">
            Create Account
          </h2>
          <Formik
            initialValues={
              {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                email: "",
                password: "",
                confirmPassword: "",
                agreement: false,
                isAgent: false,
              } as FormData
            }
            validationSchema={SignUpValidation}
            onSubmit={onSubmit}
          >
            <Former>
              <InputField name="firstName" label="First name" placeholder="" />
              <InputField name="lastName" label="Last name" placeholder="" />
              <PhoneFormatter name="phoneNumber" label="Phone Number" />

              <InputField name="email" label="Email" placeholder="" />

              <InputField
                name="password"
                label="Password"
                placeholder=""
                inputType="password"
              />

              <InputField
                name="confirmPassword"
                label="Confirm Password"
                placeholder=""
                inputType="password"
              />

              <CheckBoxField
                label={
                  <p>Check this box if you are signing up as a Business </p>
                }
                name="isAgent"
              />

              <CheckBoxField
                label={
                  <>
                    By checking this box, you confirm that you've read and
                    accepted our{" "}
                    <a
                      href="https://app.termly.io/document/terms-of-service/52cfeaf3-bc98-4aee-8e68-ae9526870a50"
                      target="_blank"
                      rel="noreferrer"
                      className="text-[#0076E9]"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://app.termly.io/document/privacy-policy/22fda0c1-0085-4dac-aabd-11a41a97895a"
                      target="_blank"
                      rel="noreferrer"
                      className="text-[#0076E9]"
                    >
                      Privacy Policy.
                    </a>
                  </>
                }
                name="agreement"
              />

              <SignupBtn disabled={isLoading}>
                {isLoading && <Spinner color={Colors.White} size={14} />}
                Get Started
              </SignupBtn>
            </Former>
          </Formik>

          <p className="my-5 text-center">
            Already have an account?{" "}
            <button
              onClick={() => navigate(WebPaths.Auth)}
              className="text-[#0076E9]"
              type="submit"
            >
              Log In
            </button>
          </p>
        </div>
        <AuthRightSession />
      </div>
      {isVerified && <Verified onClose={onCloseModal} userID={userID} />}
      {openEmailNotif && <EmailNotif onClose={() => setEmailNotif(false)} />}
    </div>
  );
};
export default Join;

const SignupBtn = styled.button`
  padding: 0.8rem 0;
  text-align: center;
  background-color: ${Colors.Blue00};
  width: 100%;
  border-radius: 0.6rem;
  margin-top: 1rem;

  transition: 0.3s all ease;
  color: #fff;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    background: #022e58;
  }
`;

const Former = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
