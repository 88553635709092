import React, { FC, useEffect } from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik } from "formik";
import InputField from "../../../components/formik fields/InputField";
import FormBtn from "../../../components/FormBtn";
import SelectField from "../../../components/formik fields/SelectField";
import { Modify, SingleOption } from "../../../../../types/types";
import { saveDS160SivesObj } from "../../../../../api/ds160/types";
import {
  useGetDS160Sives,
  useSaveDS160Sives,
} from "../../../../../hooks/ds160/usDS160";
import { PrimaryOccupationData, processReviewData, USText } from "../data";
import { HalfFormWrapper, Info, InfoBold } from "../styles";
import { StudentExchangeVisaSEVISValidation } from "../validation";
import { initInput, initSelect } from "../../../components/data";
import useToNextSection from "../../../../../hooks/useToNextSection";
import useStatesByCountry from "../../../components/useStatesByCountry";
import { ReviewSEVSectionB } from "../Review/components/data";
import useAiReview from "../useAiReview";

export interface StudentExchangeVisaSEVISFormData
  extends Modify<
    saveDS160SivesObj,
    {
      courseOfStudy: SingleOption | null;
      stateOrProvince: SingleOption | null;
    }
  > {}

interface Props {
  isToStudy: boolean;
}

const StudentExchangeVisaSEVIS: FC<Props> = ({ isToStudy }) => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetDS160Sives();
  const { mutate, isLoading: isSaving } = useSaveDS160Sives();
  const { formattedStateData, stateLoading } = useStatesByCountry(USText);
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  //skip this section if not to study
  useEffect(() => {
    if (!isToStudy) {
      toNextSection();
    }
  }, [isToStudy, toNextSection]);

  const initialValues: StudentExchangeVisaSEVISFormData = {
    sevisID: initInput(data?.sevisID),
    nameOfSchool: initInput(data?.nameOfSchool),
    courseOfStudy: initSelect(PrimaryOccupationData, data?.courseOfStudy),
    streetAddressLine1: initInput(data?.streetAddressLine1),
    streetAddressLine2: initInput(data?.streetAddressLine2),
    city: initInput(data?.city),
    stateOrProvince: initSelect(formattedStateData, data?.stateOrProvince),
    zipCode: initInput(data?.zipCode),
  };
  const onSubmit = (values: StudentExchangeVisaSEVISFormData) => {
    const { courseOfStudy, stateOrProvince, ...rest } = values;

    if (!courseOfStudy || !stateOrProvince) return;

    const payload = {
      ...rest,
      courseOfStudy: courseOfStudy.value,
      stateOrProvince: stateOrProvince.value,
    };

    const section = ReviewSEVSectionB(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      sev: {
        ...riskData?.sev,
        ...result,
      },
    };

    const onSave = () => {
      mutate(payload, {
        onSuccess: toNextSection,
      });
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || riskLoading}
      isError={isError || riskError}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your Student
            Exchange Visa
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={StudentExchangeVisaSEVISValidation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <Header>SEVIS Information</Header>

              <HalfFormWrapper>
                <InputField label="SEVIS ID" placeholder="" name="sevisID" />
                <p className="-mt-5 text-[14px] font-[500]">
                  SEVIS ID starts with "N" and followed by 10 digits.
                </p>
                <InfoBold>Provide Additional Information Below</InfoBold>
                <InputField
                  label="Name of School"
                  placeholder=""
                  name="nameOfSchool"
                />

                <SelectField
                  name="courseOfStudy"
                  label="Course Of Study"
                  placeholder="-Select-"
                  options={PrimaryOccupationData}
                />

                <InputField
                  label="Street Address Line 1"
                  placeholder=""
                  name="streetAddressLine1"
                />

                <InputField
                  label="Street Address Line 2"
                  coloredLabel="(optional)"
                  placeholder=""
                  name="streetAddressLine2"
                />

                <InputField label="City" placeholder="" name="city" />

                <SelectField
                  name="stateOrProvince"
                  label="State/Province"
                  placeholder="-Select-"
                  options={formattedStateData}
                  isLoading={stateLoading}
                />

                <InputField
                  label="Postal Code/Zip Code"
                  placeholder=""
                  name="zipCode"
                />

                <FormBtn
                  isLoading={isSaving || riskSaving}
                  addBackBtn
                  isError={formik.dirty && !formik.isValid}
                />
              </HalfFormWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default StudentExchangeVisaSEVIS;

const Wrapper = styled.div``;

const Header = styled(InfoBold)`
  margin-bottom: 2rem;
`;
