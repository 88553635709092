import { ApiResponse } from "../../types/types";
import request from "../request";
import {
  AssessmentDs160DataPayload,
  AssessmentDs160Payload,
  GetChatGptDs160AssessmentRes,
  GetInterviewHistoryRes,
  saveInterviewPayload,
} from "./types";
import aiRequest from "../aiRequest";

export const saveInterview = async (payload: saveInterviewPayload) => {
  return await request.post<ApiResponse>(
    `/ai/interview-support${payload.id ? `?id=${payload.id}` : ""}`,
    payload.payload
  );
};

export const saveChat = async (payload: saveInterviewPayload) => {
  return await request.post<ApiResponse>(
    `/ai/customer-support${payload.id ? `?id=${payload.id}` : ""}`,
    payload.payload
  );
};

export const getInterviewHistory = async () => {
  return await request.get<GetInterviewHistoryRes>(`/ai/get-interview-history`);
};

export const getInterviewVoiceHistory = async () => {
  return await request.get<GetInterviewHistoryRes>(
    `/ai/get-interview-history-voice`
  );
};

export const aiExtractData = async (payload: FormData) => {
  return await request.post(`/ai/extract-data`, payload);
};

export const aiModelQuery = async (payload: any) => {
  return await aiRequest.post<ApiResponse>(`/model-query`, payload);
};

export const deleteInterviewHistory = async () => {
  return await request.delete<GetInterviewHistoryRes>(
    `ai/clear-interview-history`
  );
};

export const postRiskAssessment = async (payload: any) => {
  return await request.post<ApiResponse>(`ai/risk-assessment`, payload);
};

export const riskAssessmentPersonalInfo = async (
  payload: AssessmentDs160Payload
) => {
  const { errorCheck, data } = payload;
  return await request.post<ApiResponse>(`/ai/ds-160-personal-info`, data, {
    params: {
      errorCheck,
    },
  });
};

export const getDS160AIData = async () => {
  return await request.get<ApiResponse<Partial<AssessmentDs160DataPayload>>>(
    `/ai/get-ds-160-ai-data`
  );
};

export const assessmentDs160Travel = async (
  payload: AssessmentDs160Payload
) => {
  const { errorCheck, data } = payload;
  return await request.post<ApiResponse>(`/ai/ds-160-travel`, data, {
    params: {
      errorCheck,
    },
  });
};

export const assessmentDs160AddressPhone = async (
  payload: AssessmentDs160Payload
) => {
  const { errorCheck, data } = payload;
  return await request.post<ApiResponse>(`/ai/ds-160-address-and-phone`, data, {
    params: {
      errorCheck,
    },
  });
};

export const assessmentDs160Passport = async (
  payload: AssessmentDs160Payload
) => {
  const { errorCheck, data } = payload;
  return await request.post<ApiResponse>(`/ai/ds-160-passport`, data, {
    params: {
      errorCheck,
    },
  });
};

export const assessmentDs160USContact = async (
  payload: AssessmentDs160Payload
) => {
  const { errorCheck, data } = payload;
  return await request.post<ApiResponse>(`/ai/ds-160-us-contact`, data, {
    params: {
      errorCheck,
    },
  });
};

export const assessmentDs160Family = async (
  payload: AssessmentDs160Payload
) => {
  const { errorCheck, data } = payload;
  return await request.post<ApiResponse>(`/ai/ds-160-family`, data, {
    params: {
      errorCheck,
    },
  });
};

export const assessmentDs160WET = async (payload: AssessmentDs160Payload) => {
  const { errorCheck, data } = payload;
  return await request.post<ApiResponse>(`/ai/ds-160-wet`, data, {
    params: {
      errorCheck,
    },
  });
};

export const assessmentDs160Sevis = async (payload: AssessmentDs160Payload) => {
  const { errorCheck, data } = payload;
  return await request.post<ApiResponse>(`/ai/ds-160-sevis`, data, {
    params: {
      errorCheck,
    },
  });
};

export const assessmentDs160Security = async (
  payload: AssessmentDs160Payload
) => {
  const { errorCheck, data } = payload;
  return await request.post<ApiResponse>(`/ai/ds-160-security`, data, {
    params: {
      errorCheck,
    },
  });
};

export const saveDs160Assessment = async (
  payload: Partial<AssessmentDs160DataPayload>
) => {
  return await request.post<ApiResponse>(`/ai/save-ds-160-ai-data`, payload);
};

export const getDs160Assessment = async () => {
  return await request.get<ApiResponse>(`/ai/get-ai-review`);
};

export const getChatGptDs160Assessment = async () => {
  return await request.get<GetChatGptDs160AssessmentRes>(
    `/ai/get-ai-review-openai`
  );
};
