import { FC } from "react";
import { createPortal } from "react-dom";
import Overlay from "../checkEligibilty/components/modals/Overlay";
import { ContainerVariants } from "../checkEligibilty/components/modals/variant";
import { mediaObj } from "../../../styles/Media";
import styled from "styled-components";
import { motion } from "framer-motion";
import Colors from "../../../styles/Colors";
import { CaseHistoryObj } from "../../../types/types";
import { ImCancelCircle } from "react-icons/im";
import DownloadFormsContent from "./DownloadFormsContent";
import { FormCategories } from "../../../routes/type";
import useDownloadTrigger from "../../../hooks/useDownloadTrigger";
import { SpinnerWrapper } from "./styles";
import Spinner from "../../../utils/Spinner";
import {
  useGetDS160Eligibility,
  useGetDS160Info,
} from "../../../hooks/ds160/usDS160";
import { DS160PrimaryReasonForVisitingLabels } from "../checkEligibilty/components/types";

interface Props {
  onClose: () => void;
  data: CaseHistoryObj;
}

const DownloadForms: FC<Props> = ({ onClose, data }) => {
  const { triggerError, triggerFetching } = useDownloadTrigger(data.caseType);
  const isDs160 = data.caseType === FormCategories.ds160;
  const {
    data: ds160EligibilityData,
    isLoading: ds160EligibilityLoading,
    isError: ds160EligilibiltyError,
  } = useGetDS160Eligibility(isDs160);
  const {
    data: ds160InfoData,
    isLoading: ds160InfoLoading,
    isError: ds160InfoError,
  } = useGetDS160Info(isDs160);

  const isError = isDs160
    ? ds160EligilibiltyError || ds160InfoError
    : triggerError;
  const isFetching = isDs160
    ? ds160EligibilityLoading || ds160InfoLoading
    : triggerFetching;

  const isToStudy = ds160EligibilityData
    ? ds160EligibilityData.primary_reason_for_visiting_us ===
      DS160PrimaryReasonForVisitingLabels["To study"]
    : undefined;

  return createPortal(
    <Overlay onClose={onClose}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Cancel onClick={onClose}>
          <ImCancelCircle />
        </Cancel>
        <Header>Download Documents</Header>
        {isFetching && !isError && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}
        {!isFetching && isError && (
          <SpinnerWrapper>
            An error occured. Please try again later.
          </SpinnerWrapper>
        )}

        {!isFetching && !isError && (
          <DownloadFormsContent
            data={data}
            hideFilingForm={data.caseType === FormCategories.ds160}
            hideG1145Form={data.caseType === FormCategories.ds160}
            formType={data.caseType}
            isDs160={isDs160}
            isToStudy={isToStudy}
            ds160InfoData={ds160InfoData}
          />
        )}
      </Wrapper>
    </Overlay>,
    document.body
  );
};

export default DownloadForms;

const Wrapper = styled(motion.div)`
  width: 437px;
  height: max-content;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 40px 32px;
  position: relative;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 60vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Cancel = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  color: ${Colors.RedC3};
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Header = styled.h2`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
  color: ${Colors.Blue00};
`;
