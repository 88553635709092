import React from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik } from "formik";
import FormBtn from "../../../components/FormBtn";
import { saveDS160SecurityBackgroundP4Obj } from "../../../../../api/ds160/types";
import {
  useGetDS160SecurityBackgroundP4,
  useSaveDS160SecurityBackgroundP4,
} from "../../../../../hooks/ds160/usDS160";
import { SecurityBackgroundP4Validation } from "../validation";
import { HalfFormWrapper, Info, InfoBold } from "../styles";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  initInput,
} from "../../../components/data";
import RadioComp from "../../../components/RadioComp";
import { resetDynamicFields } from "../../../components/data";
import InputField from "../../../components/formik fields/InputField";
import useToNextSection from "../../../../../hooks/useToNextSection";
import { getSecurityBackgroundValidations, processReviewData } from "../data";
import { ReviewSecuritySectionD } from "../Review/components/data";
import useAiReview from "../useAiReview";

const SecurityBackgroundP4 = () => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetDS160SecurityBackgroundP4();
  const { mutate, isLoading: isSaving } = useSaveDS160SecurityBackgroundP4();
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const onSubmit = (values: saveDS160SecurityBackgroundP4Obj) => {
    const payload = values;

    const section = ReviewSecuritySectionD(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      security: {
        ...riskData?.security,
        ...result,
      },
    };

    const onSave = () => {
      mutate(payload, {
        onSuccess: toNextSection,
      });
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  const initialValues: saveDS160SecurityBackgroundP4Obj = {
    partFourI: data?.partFourI || "",
    explanationPartFourI: initInput(data?.explanationPartFourI),
    partFourII: data?.partFourII || "",
    explanationPartFourII: initInput(data?.explanationPartFourII),
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || riskLoading}
      isError={isError || riskError}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your Security and
            Background.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={SecurityBackgroundP4Validation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <HalfFormWrapper>
                <RadioComp
                  title="Have you ever sought to obtain or assist others to obtain a
                visa, entry, into the United States, or any other united
                states immigration benefit by fraud or willful
                misrepresentation or other unlawful means?"
                  name="partFourI"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partFourI"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartFourI", value: "" },
                    ]);
                  }}
                />

                {formik.values.partFourI === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartFourI"
                      inputType="text-area"
                    />
                  </>
                )}

                <RadioComp
                  title="Have you ever been removed or deported from any country?"
                  name="partFourII"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partFourII"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartFourII", value: "" },
                    ]);
                  }}
                />

                {formik.values.partFourII === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartFourII"
                      inputType="text-area"
                    />
                  </>
                )}

                <FormBtn
                  isLoading={isSaving || riskSaving}
                  addBackBtn
                  isError={formik.dirty && !formik.isValid}
                />
              </HalfFormWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default SecurityBackgroundP4;

const Wrapper = styled.div``;
